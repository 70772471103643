import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const NewEditUser = ({ title, message, onClose, onSubmit }) => (
  <Dialog open onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText style={{ minWidth: "32rem" }}>
        {message}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onClose}
        variant="contained"
        autoFocus
        style={{ boxShadow: "none" }}
      >
        No
      </Button>
      <Button
        onClick={onSubmit}
        variant="contained"
        autoFocus
        style={{ boxShadow: "none", margin: "1rem" }}
      >
        Yes
      </Button>
    </DialogActions>
  </Dialog>
);

export default NewEditUser;
