import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";

import APIService from "../api/api";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 64px);
  @media (max-width: 600px) {
    height: calc(100vh - 56px);
  }
  overflow: hidden;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  height: 100%;
  padding: 2rem;
  overflow-y: auto;
`;

const Logs = ({ credentials }) => {
  const history = useHistory();
  const [logs, setLogs] = useState("");

  const getLogs = () => {
    APIService.getLogs().then((result) => result && setLogs(result));
  };

  useEffect(() => {
    if (credentials) {
      getLogs();
    }
  }, [credentials]);

  if (!credentials) {
    history.push("/login");
    return null;
  }

  if (!credentials.adminRole) {
    history.push("/");
    return null;
  }

  return (
    <Container>
      <InnerContainer>
        <Typography variant="h5" style={{ marginBottom: "1rem" }}>
          Logs
        </Typography>

        <div
          style={{
            backgroundColor: "rgba(0,0,0,0.1)",
            height: "1px",
            minHeight: "1px",
            width: "100%",
            marginBottom: "1rem",
          }}
        />

        <Grid style={{ overflow: "auto" }}>
          <code
            style={{
              display: "block",
              fontSize: "1.2rem",
              whiteSpace: "pre-wrap",
            }}
          >
            {logs}
          </code>
        </Grid>
      </InnerContainer>
    </Container>
  );
};

export default Logs;
