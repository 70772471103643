import React from "react";
import styled, { keyframes } from "styled-components";
import PropTypes from "prop-types";

const svgAnimation = keyframes`
  0% { 
      transform: rotateZ(0deg);
  }

  100% { 
      transform: rotateZ(360deg);
  }
`;

const circleAnimation = keyframes`
    0%,
    
    25% {
      stroke-dashoffset: 280;
      transform: rotate(0);
    }

    50%,

    75% {
      stroke-dashoffset: 75;
      transform: rotate(45deg);
    }

    100% {
      stroke-dashoffset: 280;
      transform: rotate(360deg);
    }
`;

const Svg = styled.svg.attrs({
  viewBox: "0 0 100 100",
  xmlns: "http://www.w3.org/2000/svg",
})`
  max-width: 100px;
  animation-name: ${svgAnimation};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

const Circle = styled.circle.attrs({
  cx: "50",
  cy: "50",
  r: "45",
})`
  display: block;
  fill: transparent;
  stroke: ${(props) => props.color};
  stroke-linecap: round;
  stroke-dasharray: 283;
  stroke-dashoffset: 280;
  stroke-width: 10px;
  transform-origin: 50% 50%;

  animation-name: ${circleAnimation};
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
`;

// Credit - Glenn McComb
// https://glennmccomb.com/articles/building-a-pure-css-animated-svg-spinner/

const Spinner = (props) => {
  const { size, color, style } = props;

  return (
    <div
      style={{
        width: size,
        height: size,
        minWidth: size,
        minHeight: size,
        ...style,
      }}
    >
      <Svg>
        <Circle color={color} />
      </Svg>
    </div>
  );
};

Spinner.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.shape({}),
};

Spinner.defaultProps = {
  size: "auto",
  color: "gray",
  style: {},
};

export default Spinner;
