/* eslint-disable camelcase */
import axios from "axios";

// Thanks to: Mario Cesar
// https://gist.github.com/mariocesar/e96f6cf6cb2db213173a9c08b9a9867a

const singleton = Symbol(); // eslint-disable-line
const singletonEnforcer = Symbol(); // eslint-disable-line

class ApiService {
  id = null;

  session = null;

  isRefreshing = false;

  refreshSubscribers = [];

  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw new Error("Cannot construct singleton");
    }

    this.id = Math.floor(
      Math.random() * Math.floor(Math.random() * Date.now())
    );

    this.session = axios.create({ baseURL: process.env.REACT_APP_API });

    // Refresh Token
    // //////////////////////////////////////////////////////////////////////////////////////////////////////////
    this.isRefreshing = false;
    this.refreshSubscribers = [];

    this.getCredentials = () =>
      JSON.parse(localStorage.getItem("credentials") || "{}");

    this.setCredentials = (credentials) => {
      if (!credentials || !credentials?.accessToken) {
        localStorage.removeItem("credentials");
        window.location.href = `${window.location.origin}${window.location.pathname}`;
      } else if (credentials && credentials.accessToken) {
        localStorage.setItem("credentials", JSON.stringify(credentials));
      }
    };

    this.refreshAccessToken = (
      credentials = JSON.parse(localStorage.getItem("credentials") || "{}")
    ) =>
      new Promise((resolve, reject) => {
        if (credentials.refreshToken) {
          this.session
            .post("/refresh-token", {
              refreshToken: credentials.refreshToken,
            })
            .then(async (response) => {
              this.setCredentials({ ...credentials, ...response.data });
              resolve({ ...credentials, ...response.data });
            })
            .catch((error) => {
              this.setCredentials();
              reject(error);
            });
        } else {
          this.setCredentials();
          reject(new Error("No refresh token stored"));
        }
      });

    this.subscribeTokenRefresh = (callback) => {
      this.refreshSubscribers.push({
        // Unique ID
        id: Math.floor(Math.random() * Math.floor(Math.random() * Date.now())),
        isPending: true,
        callback,
      });
    };

    this.onRefreshed = (credentials) => {
      this.refreshSubscribers.forEach((subscriber) => {
        subscriber.callback(credentials);
        subscriber.isPending = false;
      });
      this.refreshSubscribers = [
        ...this.refreshSubscribers.filter((subscriber) => subscriber.isPending),
      ];
    };

    this.onRefreshError = (err) => {
      this.refreshSubscribers = [];
      this.setCredentials();
    };

    // //////////////////////////////////////////////////////////////////////////////////////////////////////////

    // Request
    this.session.interceptors.request.use(
      (config) => {
        const credentials = this.getCredentials();
        if (credentials.accessToken) {
          config.headers.Authorization = `${credentials.tokenType} ${credentials.accessToken}`;
        }

        config.baseURL = process.env.REACT_APP_API;

        return config;
      },
      (error) => Promise.reject(error)
    );

    // Response
    this.session.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.config.url !== "/login"
        ) {
          const { config } = error;
          const originalRequest = config;

          if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshAccessToken()
              .then((credentials) => {
                this.isRefreshing = false;
                this.onRefreshed(credentials);
              })
              .catch((err) => {
                this.onRefreshError(err);
              });
          }

          const retryOriginalRequest = new Promise((resolve) => {
            this.subscribeTokenRefresh((credentials) => {
              originalRequest.headers.Authorization = `${credentials.tokenType} ${credentials.accessToken}`;
              resolve(this.session(originalRequest));
            });
          });

          return retryOriginalRequest;
        }

        return Promise.reject(error);
      }
    );
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new ApiService(singletonEnforcer);
    }

    return this[singleton];
  }

  get = (...params) => this.session.get(...params);

  post = (...params) => this.session.post(...params);

  put = (...params) => this.session.put(...params);

  patch = (...params) => this.session.patch(...params);

  delete = (...params) => this.session.delete(...params);
}

const api = ApiService.instance;

const showError = (error) =>
  document.dispatchEvent(new CustomEvent("showMessage", { detail: error }));

const APIService = {
  login: (data) =>
    api
      .post(`/login`, data)
      .then(({ data }) => data)
      .catch((error) => {
        showError(error);
        return null;
      }),

  logout: () => api.get(`/logout`),

  forgotPassword: (data) =>
    api
      .post(`/forgot-password`, data)
      .then(({ data }) => data)
      .catch((error) => {
        showError(error);
        return null;
      }),

  resetPassword: (data) =>
    api
      .post(`/reset-password`, data)
      .then(({ data }) => data)
      .catch((error) => {
        showError(error);
        return null;
      }),

  getUsers: (params) =>
    api
      .get(`/users`, { params })
      .then(({ data }) => data)
      .catch((error) => {
        showError(error);
        return null;
      }),

  createUser: (data) =>
    api
      .post(`/users`, data)
      .then(({ data }) => data)
      .catch((error) => {
        showError(error);
        return null;
      }),

  updateUser: (id, data) =>
    api
      .patch(`/users/${id}`, data)
      .then(({ data }) => data)
      .catch((error) => {
        showError(error);
        return null;
      }),

  deleteUser: (id) =>
    api
      .delete(`/users/${id}`)
      .then(({ data }) => data)
      .catch((error) => {
        showError(error);
        return null;
      }),

  changeUserPassword: (data) =>
    api
      .put(`/users/change-password`, data)
      .then(({ data }) => data)
      .catch((error) => {
        showError(error);
        return null;
      }),

  getLogs: (params) =>
    api
      .get(`/logs`, { params })
      .then(({ data }) => data)
      .catch((error) => {
        showError(error);
        return null;
      }),

  getFiles: (params) =>
    api
      .get(`/repository`, { params })
      .then(({ data }) => data)
      .catch((error) => {
        showError(error);
        return null;
      }),

  postFile: (fileData, config) =>
    api
      .post(`/repository`, fileData, config)
      .then(({ data }) => data)
      .catch((error) => {
        showError(error);
        return null;
      }),

  patchFile: (fileId, fileData) =>
    api
      .patch(`/repository/${fileId}`, fileData)
      .then(({ data }) => data)
      .catch((error) => {
        showError(error);
        return null;
      }),

  deleteFile: (fileId) =>
    api
      .delete(`/repository/${fileId}`)
      .then(({ data }) => data)
      .catch((error) => {
        showError(error);
        return null;
      }),

  downloadFile: (fileId) =>
    api
      .get(`/repository/${fileId}`, { responseType: "blob" })
      .then(({ data }) => window.URL.createObjectURL(new Blob([data])))
      .catch(async (error) => {
        if (error.response?.data) {
          error.response?.data.text().then((result) => {
            try {
              showError(JSON.parse(result));
            } catch {
              showError(error);
            }
          });
        } else {
          showError(error);
          return null;
        }
      }),
};

export default APIService;
