import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import Checkbox from "@material-ui/core/Checkbox";

import APIService from "../api/api";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90vw;
  max-width: 720px;
  height: auto;
  background-color: white;
  border-radius: 0.3rem;
  transform: translate(-50%, -50%);
  padding: 2rem;
`;

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1rem;
`;

const newUser = {
  id: null,
  firstName: "",
  lastName: "",
  email: "",
  adminRole: false,
  signerRole: false,
  superUser: false,
  status: "enabled",
};

const validateEmail = (email) => {
  const regEx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regEx.test(email);
};

const NewEditUser = ({ onClose, onSubmit, user = { ...newUser } }) => {
  const [state, setState] = useState({ ...user });

  const [loading, setLoading] = useState(false);

  const onChange = ({ target }) => {
    setState((oldState) => ({
      ...oldState,
      [target.name]: target.type === "checkbox" ? target.checked : target.value,
      ...(target.name === "superUser" && target.checked
        ? { adminRole: false, signerRole: false }
        : {}),
      ...(["signerRole", "adminRole"].includes(target.name) && target.checked
        ? { superUser: false }
        : {}),
    }));
  };

  const submit = (e) => {
    e.preventDefault();
    setLoading(true);

    const params = {};

    Object.keys(state).forEach((key) => {
      if (state[key] !== user[key]) {
        params[key] = state[key];
      }
    });

    if (!user.id) {
      APIService.createUser(state).then((result) => {
        setLoading(false);
        onSubmit(result);
      });
    } else {
      APIService.updateUser(user.id, params).then((result) => {
        setLoading(false);
        onSubmit(result);
      });
    }
  };

  return (
    <Modal open>
      <form noValidate style={loading ? { pointerEvents: "none" } : {}}>
        <Container>
          <FlexContainer>
            <Typography variant="h4" style={{ marginBottom: "1rem" }}>
              {user.id ? "Edit" : "New"} User
            </Typography>
          </FlexContainer>

          <TextField
            label="First Name"
            name="firstName"
            variant="outlined"
            fullWidth
            autoFocus
            value={state.firstName}
            onChange={onChange}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              autoComplete: "off",
              role: "presentation",
              style: { fontSize: "16px" },
            }}
          />

          <TextField
            label="Last Name"
            name="lastName"
            variant="outlined"
            fullWidth
            value={state.lastName}
            onChange={onChange}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              autoComplete: "off",
              role: "presentation",
              style: { fontSize: "16px" },
            }}
          />

          <TextField
            label="Email"
            name="email"
            variant="outlined"
            fullWidth
            value={state.email}
            onChange={onChange}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              autoComplete: "off",
              role: "presentation",
              style: { fontSize: "16px" },
            }}
          />

          <FlexContainer>
            <Typography variant="body">Administrator</Typography>
            <Checkbox
              checked={state.adminRole}
              onChange={onChange}
              name="adminRole"
              style={{ marginRight: "2rem" }}
            />
            <Typography variant="body">Signer</Typography>
            <Checkbox
              checked={state.signerRole}
              onChange={onChange}
              name="signerRole"
            />
            <Typography variant="body">Super User</Typography>
            <Checkbox
              checked={state.superUser}
              onChange={onChange}
              name="superUser"
            />
          </FlexContainer>

          <FlexContainer>
            <Button
              variant="contained"
              style={{ boxShadow: "none", marginLeft: "auto" }}
              onClick={onClose}
              disabled={loading}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ boxShadow: "none" }}
              disabled={
                !state.firstName.trim() ||
                !state.email.trim() ||
                !validateEmail(state.email) ||
                loading
              }
              onClick={submit}
            >
              {user.id ? "Update" : "Create"} User
            </Button>
          </FlexContainer>
        </Container>
      </form>
    </Modal>
  );
};

export default NewEditUser;
