import React from "react";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";

const theme = createTheme({
  palette: {
    type: "dark",
    primary: { main: "#fff" },
  },
});

const ResetLinkSent = ({ credentials }) => {
  const history = useHistory();

  if (credentials) {
    history.push("/");
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          padding: "2rem",
          gap: "2rem",
          color: "white",
          fontSize: "1.2rem",
        }}
      >
        <span>
          A password reset message was sent to your email address.
          <br />
          Please click the link in that message to reset your password.
        </span>

        <div
          style={{ display: "flex", alignItems: "center", marginTop: "2rem" }}
        >
          <div
            style={{
              color: "white",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              font: "1rem",
            }}
            onClick={() => {
              history.push("/login");
            }}
          >
            <ArrowBack />
            Back to Login
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default ResetLinkSent;
