import React, { useState } from "react";
import {
  makeStyles,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

import APIService from "../api/api";

const useStyles = makeStyles(() => ({
  form: {
    padding: "2rem",
    maxWidth: "46rem",
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },
  button: {
    minWidth: "16rem",
    marginLeft: "auto",
  },
}));

const theme = createTheme({
  palette: {
    type: "dark",
    primary: { main: "#fff" },
  },
});

const ResetPassword = ({ credentials, onSuccess }) => {
  const history = useHistory();
  const classes = useStyles();
  const [state, setState] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    token: null,
  });

  const queryParams = window.location.hash.split("?");

  if (queryParams.length > 1 && (!state.email || !state.token)) {
    const hashToObject = queryParams[1]
      .split("&")
      .map((v) => v.split("="))
      .reduce((pre, [key, value]) => ({ ...pre, [key]: value }), {});
    setState((oldState) => ({
      ...oldState,
      email: hashToObject.email,
      token: hashToObject.token,
    }));
  }

  if (credentials) {
    history.push("/");
    return null;
  }

  const reset = (e) => {
    e.preventDefault();

    APIService.resetPassword({
      email: state.email,
      token: state.token,
      password: state.password,
    }).then((data) => {
      if (data) {
        localStorage.setItem("credentials", JSON.stringify(data));
        onSuccess(data);
      }
    });
  };

  const onChange = ({ target }) => {
    setState((oldState) => ({ ...oldState, [target.name]: target.value }));
  };

  return (
    <form noValidate autoComplete="off" className={classes.form}>
      <ThemeProvider theme={theme}>
        <TextField
          label="Email"
          name="email"
          variant="outlined"
          fullWidth
          value={state.email}
          dark="true"
          onChange={onChange}
          InputProps={{
            autoComplete: "off",
            readOnly: true,
            role: "presentation",
            style: { fontSize: "16px" },
          }}
        />
        <TextField
          label="Password"
          name="password"
          variant="outlined"
          fullWidth
          autoFocus
          value={state.password}
          dark="true"
          onChange={onChange}
          InputProps={{
            autoComplete: "off",
            role: "presentation",
            type: "password",
            style: { fontSize: "16px" },
          }}
        />
        <TextField
          label="Confirm Password"
          name="confirmPassword"
          variant="outlined"
          fullWidth
          value={state.confirmPassword}
          dark="true"
          onChange={onChange}
          InputProps={{
            autoComplete: "off",
            role: "presentation",
            type: "password",
            style: { fontSize: "16px" },
          }}
        />

        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              color: "yellow",
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            Password must contain at least 8 characters long
          </div>

          <Button
            type="submit"
            variant="contained"
            size="large"
            className={classes.button}
            dark="true"
            style={{ fontSize: "14px" }}
            onClick={reset}
            disabled={
              !state.email ||
              !state.token ||
              !state.password.trim() ||
              state.password.length < 8 ||
              state.password !== state.confirmPassword
            }
          >
            Reset Password
          </Button>
        </div>
      </ThemeProvider>
    </form>
  );
};

export default ResetPassword;
