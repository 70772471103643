import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import Chip from "@material-ui/core/Chip";
import {
  Add,
  Check,
  Close,
  Delete,
  Brightness1,
  Refresh,
  ArrowDownward,
} from "@material-ui/icons";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import APIService from "../api/api";
import util from "../common/util";

import NewFile from "./NewFile";
import DeleteDialog from "./DeleteDialog";
import Spinner from "./Spinner";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 64px);
  @media (max-width: 600px) {
    height: calc(100vh - 56px);
  }
  overflow: hidden;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  height: 100%;
  padding: 2rem;
  overflow-y: auto;
`;

const Actions = styled(Grid)`
  opacity: 0.1;
  transition: all 0.3s ease;
  text-align: end;
  @media (max-width: 600px) {
    display: none;
  }
`;

const Row = styled(Grid)`
  align-items: center;
  transition: all 0.3s ease;
  ${(props) =>
    props.highlighted ? "background-color: rgba(255,150,50,0.1);" : ""}
  &:hover {
    ${(props) =>
    props.highlighted
      ? "background-color: rgba(255,150,50,0.2);"
      : "background-color: rgba(0, 0, 0, 0.05);"}
  }
  &:hover ${Actions} {
    display: block;
    opacity: 1;
    @media (max-width: 600px) {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
`;

const Files = ({ credentials }) => {
  const history = useHistory();

  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [dialog, setDialog] = useState(null);
  const [state, setState] = useState({ loading: false });

  const getFiles = () => {
    APIService.getFiles().then((data) => {
      if (data) {
        data.sort((a) => (a.status === "queued" ? -1 : 0));
        setFiles(data);
      }
    });
  };

  const addFile = (file) => {
    file && setFiles((oldState) => oldState.concat(file));
  };

  const updateFile = (file, status) => {
    APIService.patchFile(file.id, { status }).then((result) => {
      result &&
        setFiles((oldState) =>
          oldState.map((row) =>
            row.id === result.id ? { ...row, ...result } : row
          )
        );
    });
  };

  const deleteFile = () => {
    APIService.deleteFile(selectedFile.id).then((result) => {
      setDialog(null);
      result &&
        setFiles((oldState) =>
          oldState.filter((row) => row.id !== selectedFile.id)
        );
    });
  };

  const downloadFile = (file) => {
    setState((oldState) => ({ ...oldState, loading: true }));
    APIService.downloadFile(file.id)
      .then((result) => {
        if (result) {
          const link = document.createElement("a");
          link.href = result;
          link.setAttribute("download", file.fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setState((oldState) => ({ ...oldState, loading: false }));
        } else {
          setState((oldState) => ({ ...oldState, loading: false }));
        }
      })
      .catch(() => {
        setState((oldState) => ({ ...oldState, loading: false }));
      });
  };

  useEffect(() => {
    let timer;

    if (credentials) {
      getFiles();

      const refreshList = () => {
        APIService.getFiles().then((data) => {
          if (data) {
            setFiles((oldState) => {
              const updatedList = [...oldState];
              data.forEach((file) => {
                const idx = updatedList.findIndex((row) => row.id === file.id);
                if (idx >= 0) {
                  updatedList[idx] = file;
                } else {
                  updatedList.push(file);
                }
              });

              return updatedList;
            });
          }
        });
        timer = setTimeout(refreshList, 10000);
      };

      timer = setTimeout(refreshList, 10000);
    }
    return () => clearTimeout(timer);
  }, [credentials]);

  if (!credentials) {
    history.push("/login");
    return null;
  }

  return (
    <Container>
      <InnerContainer>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              display: "flex",
              gap: "1rem",
              padding: "1rem 0rem",
            }}
          >
            <Typography
              variant="h5"
              style={{
                marginBottom: "1rem",
                marginRight: "auto",
                fontWeight: 600,
              }}
            >
              Files
            </Typography>

            <Fab
              size="small"
              style={{ boxShadow: "none" }}
              onClick={() => getFiles()}
            >
              <Refresh />
            </Fab>
            <Fab
              size="small"
              style={{ boxShadow: "none" }}
              onClick={() => setDialog("create")}
            >
              <Add />
            </Fab>
          </Grid>
          <Grid item xs={12} sm={12} style={{ padding: "0rem" }}>
            <div
              style={{
                backgroundColor: "rgba(0,0,0,0.1)",
                height: "1px",
              }}
            />
          </Grid>
        </Grid>

        {files.map((file) => {
          return (
            <Row
              container
              spacing={2}
              highlighted={file.status === "queued"}
              style={{
                fontSize: "1.2rem",
                marginTop: "8px",
              }}
              key={file.id}
            >
              <Grid item xs={12} sm={3}>
                <b>{file.fileName}</b>
                <br />
                <small>
                  <span
                    style={{
                      backgroundColor: "lightgray",
                      borderRadius: "8px",
                      padding: "3px 8px",
                      marginRight: "4px",
                    }}
                  >
                    {util.formatBytes(file.fileSize, 2)}
                  </span>
                  {moment(file.fileDate).format("YYYY-MM-DD HH:mm:ss")}
                </small>
              </Grid>

              <Grid item xs={12} sm={2}>
                <Brightness1
                  style={{
                    color: util.pallete[file.userId],
                    transform: "translateY(4px)",
                    marginRight: "4px",
                  }}
                />
                {`${file.user.firstName} ${file.user.lastName}`}
                <br />
                <small>{file.user.email}</small>
              </Grid>

              <Grid item xs={4} sm={2} style={{ textTransform: "capitalize" }}>
                <small>Signing Type</small>
                <br />
                <span
                  style={{
                    color:
                      file.signingType === "softsign" ? "dodgerblue" : "red",
                  }}
                >
                  {file.signingType}
                </span>
              </Grid>

              <Grid item xs={4} sm={2}>
                <small>Submitted</small>
                <br />
                {moment(file.createdAt).fromNow()}
              </Grid>

              <Grid
                item
                xs={4}
                sm={1}
                style={{ textAlign: "right", textTransform: "capitalize" }}
              >
                <Chip
                  label={file.status}
                  variant="outlined"
                  style={{
                    backgroundColor: util.getStatusColor(file.status),
                    ...(file.status === "signed" ? { paddingLeft: "6px" } : {}),
                  }}
                  icon={file.status === "signed" ? <ArrowDownward /> : null}
                  onClick={() => file.status === "signed" && downloadFile(file)}
                  clickable
                />
              </Grid>

              <Actions item xs={12} sm={2}>
                {file.status === "queued" && credentials.signerRole && (
                  <Fab
                    size="small"
                    style={{
                      boxShadow: "none",
                      marginRight: "0.5rem",
                    }}
                  >
                    <Check
                      style={{ color: "green" }}
                      onClick={() => {
                        updateFile(file, "authorized");
                      }}
                    />
                  </Fab>
                )}

                {file.status === "queued" && credentials.signerRole && (
                  <Fab
                    size="small"
                    style={{ boxShadow: "none", marginRight: "0.5rem" }}
                  >
                    <Close
                      style={{ color: "red" }}
                      onClick={() => {
                        updateFile(file, "denied");
                      }}
                    />
                  </Fab>
                )}

                {(credentials.adminRole ||
                  file.user.email === credentials.email) && (
                    <Fab size="small" style={{ boxShadow: "none" }}>
                      <Delete
                        onClick={() => {
                          setSelectedFile(file);
                          setDialog("delete");
                        }}
                      />
                    </Fab>
                  )}
              </Actions>

              <Grid item xs={12} sm={12} style={{ padding: "0rem" }}>
                <div
                  style={{
                    backgroundColor: "rgba(0,0,0,0.1)",
                    height: "1px",
                  }}
                />
              </Grid>
            </Row>
          );
        })}
      </InnerContainer>

      {dialog === "create" && (
        <NewFile
          onClose={() => setDialog(null)}
          onSubmit={(file) => {
            addFile(file);
            setDialog(null);
          }}
        />
      )}

      {dialog === "delete" && (
        <DeleteDialog
          title="Delete"
          message="Are you sure you want delete this file?"
          onClose={() => setDialog(null)}
          onSubmit={() => {
            setDialog(null);
            deleteFile();
          }}
        />
      )}

      <Dialog open={state.loading}>
        <DialogContent
          style={{
            display: "flex",
            padding: "20px",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <Spinner color="#0e5b82" size={32} />
          <Typography>Downloading file...</Typography>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default Files;
